<template>
  <div class="landing-page">
    <!-- Hero Section -->
    <v-container fluid class="hero-section py-md-12 py-6">
      <v-row align="center" justify="center" class="mx-0">
        <v-col cols="12" md="6" class="text-center text-md-left px-md-8 px-4">
          <h1 class="hero-title font-weight-bold mb-4">Streamline Your E-commerce Fulfillment</h1>
          <h2 class="hero-subtitle mb-6 ml-0">Automate your shipping process, reduce errors, and delight your customers with fast, reliable delivery.</h2>
          <div class="d-flex flex-column flex-sm-row justify-center justify-md-start">
            <v-btn
              color="primary"
              large
              class="white--text mb-3 mb-sm-0 mr-sm-4 px-4"
              elevation="2"
              href="https://apps.shopify.com/fulfillment-hero"
              rounded
            >
              TRY ON SHOPIFY
            </v-btn>
            <v-btn
              outlined
              large
              color="primary"
              elevation="1"
              to="/signin"
              rounded
              class="px-4"
            >
              SIGN IN
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="mt-6 mt-md-0 text-center px-0" >
          <v-img
            src="https://images.unsplash.com/photo-1586528116311-ad8dd3c8310d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
            alt="E-commerce fulfillment and shipping"
            class="rounded-lg mx-auto hero-image"
            max-width="90vw"
            max-height="400"
            contain
          ></v-img>
        </v-col>
      </v-row>
    </v-container>

    <!-- Features Section -->
    <v-container class="features-section py-md-12 py-8">
      <v-row class="text-center mb-8">
        <v-col cols="12">
          <h2 class="section-title font-weight-bold">Why Choose {{ $appName }}?</h2>
          <p class="section-subtitle mt-3">Everything you need to run your own Fulfillment Center</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" class="mb-6 mb-md-0">
          <v-card height="100%" class="d-flex flex-column feature-card" elevation="3" rounded="lg">
            <v-card-title class="justify-center">
              <v-icon color="primary" size="64" class="mb-4">mdi-truck-fast</v-icon>
            </v-card-title>
            <v-card-title class="justify-center pt-0 feature-title">Easily integrate with Carriers</v-card-title>
            <v-card-text class="text-center">
              <p>Ready built integrations with major carriers, such as PostNord, DHL, FedEx and more built upon request. Easily offer automated Shipping Rates at your store Check Out</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="mb-6 mb-md-0">
          <v-card height="100%" class="d-flex flex-column feature-card" elevation="3" rounded="lg">
            <v-card-title class="justify-center">
              <v-icon color="primary" size="64" class="mb-4">mdi-store</v-icon>
            </v-card-title>
            <v-card-title class="justify-center pt-0 feature-title">Multi-Store Integration</v-card-title>
            <v-card-text class="text-center">
              <p>Run your own 3PL Fulfillment Center and seamlessly connect with multiple Shopify stores</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="mx-auto mx-md-0">
          <v-card height="100%" class="d-flex flex-column feature-card" elevation="3" rounded="lg">
            <v-card-title class="justify-center">
              <v-icon color="primary" size="64" class="mb-4">mdi-chart-line</v-icon>
            </v-card-title>
            <v-card-title class="justify-center pt-0 feature-title">Automated Processes</v-card-title>
            <v-card-text class="text-center">
              <p>Label printing, efficient order picking, analytics and Real Time inventory checks - all automated!</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- How It Works Section -->
    <v-container fluid class="how-it-works-section py-md-12 py-8">
      <v-container>
        <v-row class="text-center mb-8">
          <v-col cols="12">
            <h2 class="section-title font-weight-bold">How It Works</h2>
            <p class="section-subtitle mt-3">Get started in minutes, not days</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="6" md="4" class="text-center mb-8 mb-md-0">
            <v-avatar color="primary" size="96" class="mb-4 step-avatar elevation-5">
              <span class="white--text text-h4">1</span>
            </v-avatar>
            <h3 class="step-title font-weight-bold mb-3">Connect Your Stores</h3>
            <p class="step-description">Integrate with Shopify in just a few clicks.</p>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="text-center mb-8 mb-md-0">
            <v-avatar color="primary" size="96" class="mb-4 step-avatar elevation-5">
              <span class="white--text text-h4">2</span>
            </v-avatar>
            <h3 class="step-title font-weight-bold mb-3">Set Up Your Warehouse</h3>
            <p class="step-description">Organize your inventory and configure your shipping preferences.</p>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="text-center mx-auto mx-md-0">
            <v-avatar color="primary" size="96" class="mb-4 step-avatar elevation-5">
              <span class="white--text text-h4">3</span>
            </v-avatar>
            <h3 class="step-title font-weight-bold mb-3">Start Fulfilling Orders</h3>
            <p class="step-description">Process orders automatically and ship them with your preferred carriers.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <!-- Testimonials Section -->
    <v-container class="testimonials-section py-md-12 py-8">
      <v-row class="text-center mb-8">
        <v-col cols="12">
          <h2 class="section-title font-weight-bold">What Our Customers Say</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" class="mb-6 mb-md-0">
          <v-card height="100%" class="d-flex flex-column testimonial-card" elevation="3" rounded="lg">
            <v-card-text class="text-center">
              <v-icon color="primary" size="36">mdi-format-quote-open</v-icon>
              <p class="my-4 testimonial-text">I'm incredibly satisfied with this app and recommend all my colleagues in the industry to use it! Syncs with Postnord/Unifaun, can pack multiple orders at the same time, has an incredibly good and smart inventory system, prints customs declarations, everything in one! I am so pleased, it saves me so many hours a week with this app.</p>
              <v-icon color="primary" size="36">mdi-format-quote-close</v-icon>
              <div class="mt-4">
                <p class="font-weight-bold mb-0">Smash Into Pieces</p>
                <p class="caption">Sweden</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="mb-6 mb-md-0">
          <v-card height="100%" class="d-flex flex-column testimonial-card" elevation="3" rounded="lg">
            <v-card-text class="text-center">
              <v-icon color="primary" size="36">mdi-format-quote-open</v-icon>
              <p class="my-4 testimonial-text">The multi-store integration is a game-changer. We can now manage orders from all our sales channels in one dashboard, saving us hours every day.</p>
              <v-icon color="primary" size="36">mdi-format-quote-close</v-icon>
              <div class="mt-4">
                <p class="font-weight-bold mb-0">John Alfredsson</p>
                <p class="caption">Owner, BWAB Logistics</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="mx-auto mx-md-0">
          <v-card height="100%" class="d-flex flex-column testimonial-card" elevation="3" rounded="lg">
            <v-card-text class="text-center">
              <v-icon color="primary" size="36">mdi-format-quote-open</v-icon>
              <p class="my-4 testimonial-text">This app is a dream for anyone who are shipping everything from 10 to 1000 orders a day. It automatically retrieves address information, syncs with Postnord, sends tracking information to the customer. Then there are a whole bunch of other features I haven't had time to go through yet... I am SO grateful this app exists in my life! I don't know how I managed to copy/paste shipping info before.</p>
              <v-icon color="primary" size="36">mdi-format-quote-close</v-icon>
              <div class="mt-4">
                <p class="font-weight-bold mb-0">NormandieOfficial</p>
                <p class="caption">Sweden</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="plans-section py-md-12 py-8">
      <v-row class="text-center mb-8">
        <v-col cols="12">
          <h2 class="section-title font-weight-bold">Pricing</h2>
        </v-col>
      </v-row>
      <PlansView />
    </v-container>

    <!-- CTA Section -->
    <v-container fluid class="cta-section py-md-12 py-8">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" class="text-center">
          <h2 class="cta-title font-weight-bold white--text mb-4">Ready to Streamline Your Fulfillment?</h2>
          <p class="cta-subtitle white--text mb-6">Join thousands of businesses that trust {{ $appName }} for their e-commerce operations.</p>
          <v-btn
            x-large
            color="white"
            class="primary--text px-6"
            elevation="3"
            href="https://apps.shopify.com/fulfillment-hero"
            rounded
          >
            START YOUR 14 DAY FREE TRIAL
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- Footer -->
    <v-footer class="pa-6 footer">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="mb-6 mb-md-0">
            <h3 class="title font-weight-bold mb-4">{{ $appName }}</h3>
            <p>Streamlining e-commerce fulfillment for businesses of all sizes.</p>
          </v-col>
          <!-- <v-col cols="6" sm="6" md="2" class="mb-6 mb-md-0">
            <h4 class="subtitle-1 font-weight-bold mb-4">Product</h4>
            <v-list dense class="transparent pa-0">
              <v-list-item class="pa-0 mb-2" to="/pricing">
                <v-list-item-content>
                  <v-list-item-title>Pricing</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0 mb-2" to="/features">
                <v-list-item-content>
                  <v-list-item-title>Features</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0 mb-2" to="/integrations">
                <v-list-item-content>
                  <v-list-item-title>Integrations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" sm="6" md="2" class="mb-6 mb-md-0">
            <h4 class="subtitle-1 font-weight-bold mb-4">Company</h4>
            <v-list dense class="transparent pa-0">
              <v-list-item class="pa-0 mb-2" to="/about">
                <v-list-item-content>
                  <v-list-item-title>About Us</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0 mb-2" to="/privacy">
                <v-list-item-content>
                  <v-list-item-title>Privacy Policy</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0 mb-2" to="/terms">
                <v-list-item-content>
                  <v-list-item-title>Terms of Service</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" sm="6" md="2" class="mb-6 mb-md-0">
            <h4 class="subtitle-1 font-weight-bold mb-4">Support</h4>
            <v-list dense class="transparent pa-0">
              <v-list-item class="pa-0 mb-2" to="/support">
                <v-list-item-content>
                  <v-list-item-title>Help Center</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0 mb-2" to="/contact">
                <v-list-item-content>
                  <v-list-item-title>Contact Us</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0 mb-2" to="/status">
                <v-list-item-content>
                  <v-list-item-title>System Status</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" sm="6" md="2">
            <h4 class="subtitle-1 font-weight-bold mb-4">Connect</h4>
            <div class="d-flex">
              <v-btn icon class="mr-2 social-icon">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn icon class="mr-2 social-icon">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon class="mr-2 social-icon">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
              <v-btn icon class="social-icon">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
            </div>
          </v-col> -->
        </v-row>
        <v-row class="mt-6">
          <v-col cols="12" class="text-center">
            <p class="caption mb-0">© {{ new Date().getFullYear() }} {{ $appName }}. All rights reserved.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import PlansView from '@/views/PlansView'

export default {
  name: 'Landing',
  components: {
    PlansView
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>

<style scoped>
.landing-page {
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, #f6f6f7 0%, #e9e9eb 100%);
  min-height: 60vh;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  overflow-x: hidden;
}

.hero-title {
  font-size: 3rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
  animation: fadeInUp 0.8s ease-out;
  word-wrap: break-word;
  width: 100%;
}

.hero-subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.7);
  animation: fadeInUp 0.8s ease-out 0.2s;
  animation-fill-mode: both;
  max-width: 600px;
  margin: 0 auto;
  word-wrap: break-word;
  width: 100%;
}

.hero-image {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-out 0.4s;
  animation-fill-mode: both;
  max-height: 350px;
  max-width: 100%;
  object-fit: contain;
}

.hero-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

/* Section Styles */
.section-title {
  font-size: 2.5rem;
  letter-spacing: -0.5px;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #4caf50;
  border-radius: 3px;
}

.section-subtitle {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 1.5rem;
}

/* Features Section */
.features-section {
  background-color: white;
}

.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.feature-card:hover .feature-title {
  color: #4caf50;
}

.feature-title {
  font-size: 1.5rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

/* How It Works Section */
.how-it-works-section {
  background-color: #f6f6f7;
  position: relative;
}

.step-avatar {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-avatar:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15) !important;
}

.step-title {
  font-size: 1.5rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.step-description {
  color: rgba(0, 0, 0, 0.7);
  max-width: 300px;
  margin: 0 auto;
  transition: color 0.3s ease;
}

/* Testimonials Section */
.testimonials-section {
  background-color: white;
}

.testimonial-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  position: relative;
  z-index: 1;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.testimonial-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.8);
}

/* CTA Section */
.cta-section {
  background: linear-gradient(135deg, #4caf50 0%, #2e7d32 100%);
  position: relative;
  overflow: hidden;
}

.cta-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuIiB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgcGF0dGVyblRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHJlY3QgaWQ9InBhdHRlcm4tYmFja2dyb3VuZCIgd2lkdGg9IjQwMCUiIGhlaWdodD0iNDAwJSIgZmlsbD0idHJhbnNwYXJlbnQiPjwvcmVjdD48Y2lyY2xlIGZpbGw9IiNmZmZmZmYiIGN4PSIyMCIgY3k9IjIwIiByPSIxIiBvcGFjaXR5PSIwLjA1Ij48L2NpcmNsZT48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IGZpbGw9InVybCgjcGF0dGVybikiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiPjwvcmVjdD48L3N2Zz4=');
  opacity: 0.5;
}

.cta-title {
  font-size: 2.5rem;
  letter-spacing: -0.5px;
  position: relative;
}

.cta-subtitle {
  font-size: 1.25rem;
  opacity: 0.9;
  position: relative;
}

/* Footer */
.footer {
  background-color: #f6f6f7;
}

.social-icon {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.social-icon:hover {
  transform: translateY(-3px);
  background-color: rgba(76, 175, 80, 0.1);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 960px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .cta-title {
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .hero-section {
    min-height: auto;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .hero-title {
    font-size: 1.5rem;
    margin-bottom: 0.75rem !important;
    padding: 0;
  }
  
  .hero-subtitle {
    font-size: 0.95rem;
    margin-bottom: 1.25rem !important;
    padding: 0;
    max-width: 100%;
  }
  
  .hero-image {
    max-height: 200px;
    margin-top: 1rem !important;
    width: 90%;
  }
  
  .v-btn {
    padding: 0 1rem !important;
  }
  
  .section-title {
    font-size: 1.75rem;
  }
  
  .section-subtitle, .cta-subtitle {
    font-size: 1.1rem;
  }
  
  .feature-title, .step-title {
    font-size: 1.25rem;
  }
  
  .cta-title {
    font-size: 1.75rem;
  }
  
  .testimonial-text {
    font-size: 1rem;
  }
  
  .footer .title {
    font-size: 1.5rem;
  }
  
  .footer .subtitle-1 {
    font-size: 1.1rem;
  }
}

/* Accessibility Improvements */
a:focus, button:focus {
  outline: 2px solid #4caf50;
  outline-offset: 2px;
}

.v-btn {
  font-weight: 600;
  letter-spacing: 0.5px;
}

.v-list-item:focus {
  background-color: rgba(76, 175, 80, 0.1);
}
</style>
